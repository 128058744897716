import { useI18n } from 'vue-i18n';
import type { AttachmentData } from '@sendgrid/helpers/classes/attachment';
import * as Yup from 'yup';
import { yupDe, yupEn } from '@seb-platform/shared/assets';
import { Locale } from '~/types/locales';

/**
 * Newsletter form
 */

export function getNewsletterSchema(locale: Locale) {
  const yup = getLocalizedYup(Yup, locale);
  const t = getTFunction();

  return yup.object({
    firstname: yup.string().required(t('FMContact.error.firstname.required')),
    lastname: yup.string().required(t('FMContact.error.lastname.required')),
    company: yup.string().required(t('FMContact.error.company.required')),
    email: yup.string().required(t('FMContact.error.email.required')).email(t('FMContact.error.email.invalid')),
    captcha: yup.string().required(t('FMContact.error.captcha.required')),
  });
}

export type NewsletterForm = Yup.InferType<ReturnType<typeof getNewsletterSchema>>;

export async function submitNewsletter(body: NewsletterForm) {
  return $fetch('/api/newsletter', {
    method: 'POST',
    body,
  });
}

/**
 * Contact form
 */

export function getContactSchema(locale: Locale) {
  const yup = getLocalizedYup(Yup, locale);
  const t = getTFunction();

  return yup.object({
    salutation: yup.string(),
    firstname: yup.string().required(t('FMContact.error.firstname.required')),
    lastname: yup.string().required(t('FMContact.error.lastname.required')),
    company: yup.string().required(t('FMContact.error.company.required')),
    companyCountry: yup.string().required(),
    companyPostcode: yup.string().required(t('FMContact.error.companyPostcode.required')),
    companyAddressStreet: yup.string().required(t('FMContact.error.companyAddressStreet.required')),
    companyAddressNumber: yup.string().required(t('FMContact.error.companyAddressNumber.required')),
    email: yup.string().required(t('FMContact.error.email.required')).email(t('FMContact.error.email.invalid')),
    phone: yup.string(),
    message: yup.string().required(t('FMContact.error.message.required')),
    newsletter: yup.boolean(),
    captcha: yup.string().required(t('FMContact.error.captcha.required')),
  });
}

export type ContactForm = Yup.InferType<ReturnType<typeof getContactSchema>>;

export interface ContactFormPostData {
  formData: ContactForm;
  uuid: string;
  attachments: AttachmentData[];
  page: string;
  customRecipient?: string;
}

/**
 * Gets the Yup instance with the correct locale
 */
function getLocalizedYup(yup: typeof Yup, locale: Locale) {
  if ([Locale.DE_DE, Locale.DE_AT, Locale.DE_CH].includes(locale)) {
    yup.setLocale(yupDe);
  } else {
    yup.setLocale(yupEn);
  }

  return yup;
}

/**
 * Returns the correct i18n `t()` function for the current context
 */
function getTFunction() {
  let t = (key: string) => key; // On the server, we don't have access to the i18n instance, so we use a dummy function
  if (import.meta.client) {
    const i18n = useI18n();
    t = (key: string) => i18n.t(key).toString();
  }
  return t;
}

export async function submitContact(body: ContactForm, files: File[], uuid: string, customRecipient?: string) {
  const attachments = await Promise.all(
    Array.from(files).map(async (file): Promise<AttachmentData> => {
      const reader = new FileReader();
      let content: string | undefined = await new Promise((resolve, reject) => {
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result?.toString());
        reader.onerror = () => reject(new Error('File reading error'));
      });
      content = content?.split(';base64,').pop();
      if (!content) {
        throw new Error('Could not read file content');
      }
      return {
        content,
        filename: file.name,
        type: file.type,
      };
    })
  );
  return $fetch('/api/contact', {
    method: 'POST',
    body: {
      formData: body,
      uuid: uuid,
      page: window.location.href,
      attachments,
      customRecipient,
    } satisfies ContactFormPostData,
  });
}
