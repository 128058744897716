import revive_payload_client_YTGlmQWWT5 from "/codebuild/output/src2947640763/src/seb-brand-platform/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/codebuild/output/src2947640763/src/seb-brand-platform/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/codebuild/output/src2947640763/src/seb-brand-platform/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_fWYXZjzQhM from "/codebuild/output/src2947640763/src/seb-brand-platform/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_VTarsGAzgb from "/codebuild/output/src2947640763/src/seb-brand-platform/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_PKIXU5KmsI from "/codebuild/output/src2947640763/src/seb-brand-platform/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/codebuild/output/src2947640763/src/seb-brand-platform/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_SeG0EjL5Ec from "/codebuild/output/src2947640763/src/seb-brand-platform/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src2947640763/src/seb-brand-platform/apps/wmf/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/codebuild/output/src2947640763/src/seb-brand-platform/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_lCxu0ZmxIx from "/codebuild/output/src2947640763/src/seb-brand-platform/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_5C7QFp7tsq from "/codebuild/output/src2947640763/src/seb-brand-platform/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_kpnqASteq8 from "/codebuild/output/src2947640763/src/seb-brand-platform/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_oyiomjRmnt from "/codebuild/output/src2947640763/src/seb-brand-platform/node_modules/@storyblok/nuxt/dist/runtime/plugin.js";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  _0_siteConfig_fWYXZjzQhM,
  payload_client_VTarsGAzgb,
  navigation_repaint_client_PKIXU5KmsI,
  check_outdated_build_client_LIYcCMJD18,
  chunk_reload_client_SeG0EjL5Ec,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  switch_locale_path_ssr_lCxu0ZmxIx,
  i18n_5C7QFp7tsq,
  plugin_kpnqASteq8,
  plugin_oyiomjRmnt
]