<template>
  <div
    ref="$header"
    v-editable="blok"
    class="header"
    :class="[
      themeState.isDark ? 'is-dark ' : 'is-light',
      { 'is-hidden': !headerIsVisible },
      { 'is-dimmed': headerState.dimmed },
    ]">
    <!-- Background -->
    <div
      ref="$background"
      class="background"
      :style="{
        display: renderBackgroundInDom ? 'block' : 'none',
        height: scrollState.isStickyActive ? `${scrollState.headerHeight + 56}px` : undefined,
        opacity: isBackgroundActive || isSectionsListActive ? 1 : 0,
      }">
      <div class="bottom-line" :style="{ opacity: isBackgroundActive ? 0.5 : 0 }"></div>
    </div>

    <!-- main header -->
    <div ref="$mainNavigation" class="header-main container-full" :class="{ 'is-dimmed': headerState.dimmed }">
      <!-- Section left -->
      <div class="col-left">
        <!-- Cart icon -->
        <div v-if="hasShop">
          <button
            class="header-link is-cart"
            :class="themeState.isDark ? 'dark' : 'light'"
            :aria-label="headerState.cartOpen ? t('global.hideCart') : t('global.showCart')"
            @click="() => (headerState.cartOpen = !headerState.cartOpen)">
            <ClientOnly>
              <div v-if="getCartItemNum()" class="header-link-bubble">
                {{ getCartItemNum() }}
              </div>
            </ClientOnly>
            <BaseIcon name="fi_shopping-cart" :size="16" color="inherit" />
          </button>
        </div>
      </div>

      <!-- Section center -->
      <div class="logo">
        <RouterLink
          :to="config.public.IS_HEADER_FOOTER_EXPORT ? '/' : `/${locale}/`"
          aria-label="home"
          class="header-link">
          <BaseImage v-if="logo" :image="logo" :breakpointsWidthMap="{ 0: 26, 1024: 32 }" preload />
        </RouterLink>
      </div>

      <!-- Section right -->
      <div class="col-right" :style="{ opacity: isSearchBarActive ? 0 : undefined }">
        <!-- Language switch -->
        <BmHeaderRichLanguageToggle
          v-if="blok.top_is_language_switch_enabled"
          :alternates="alternates"
          :isDark="themeState.isDark" />

        <!-- Special Link -->
        <UtilLinkItem
          v-for="(item, index) in blok.top_right_links"
          :key="index"
          :blok="item"
          class="header-link is-special-link"
          :isDark="themeState.isDark"
          :trackClick="true">
          {{ item.text }}
        </UtilLinkItem>

        <!-- Machine image -->
        <button v-if="isQuickLinksEnabled" class="product-link" @click="toggleMachinesFlyout">
          <!-- If a data product is enabled -->
          <BaseImage
            v-if="dataProduct?.content?.image_quick_link"
            :image="dataProduct?.content.image_quick_link"
            :breakpointsWidthMap="{ 0: 24, 1024: 32 }"
            class="pdp-machine" />

          <!-- Default machine images -->
          <div v-else-if="blok.top_product_defaults?.length" class="pdp-machines-container">
            <div ref="$defaultMachine" class="pdp-machines">
              <template v-for="(product, index) in blok.top_product_defaults">
                <BaseImage
                  v-if="typeof product !== 'string'"
                  :key="index"
                  ref="$defaultMachineImages"
                  :image="product?.content?.image_quick_link"
                  :breakpointsWidthMap="{ 0: 24, 1024: 32 }"
                  class="pdp-machine" />
              </template>
            </div>
          </div>
          <BaseIcon
            class="chevron-icon"
            name="fi_chevron-bottom"
            :size="16"
            :color="themeState.isDark ? 'white' : 'black'" />
        </button>

        <!-- Search icon -->
        <div v-if="blok.top_search_state === 'search'" class="search-icon-container">
          <button class="header-link" @click="toggleSearchBar(true)">
            <BaseIcon name="fi_search" :size="16" color="inherit" />
          </button>
        </div>

        <!-- Menu icon -->
        <button
          v-if="isMenuEnabled"
          class="header-link is-menu-link"
          :aria-label="t('global.openMenu')"
          @click="toggleMenu">
          <BaseIcon class="menu-toggle" name="fi_menu" :size="16" color="inherit" />
        </button>
      </div>

      <!-- Search bar -->
      <form
        v-if="isSearchBarActive && blok.top_search_state === 'search'"
        ref="$searchBar"
        class="search-bar"
        @submit="handleSearchSubmit">
        <BaseInput
          v-model="searchInputValue"
          name="search"
          class="search-bar-input"
          :isDark="themeState.isDark"
          :isSearchBar="true"
          :isAutoFocused="true"
          @escape="() => toggleSearchBar(false)"
          @submit="handleSearchSubmit" />
        <BaseLink class="header-link" @click="toggleSearchBar(false)">
          <BaseIcon name="fi_x" :size="16" :color="themeState.isDark ? 'white' : 'black'" />
        </BaseLink>
      </form>
    </div>

    <!-- In-page navigation -->
    <BMHeaderRichInPage
      v-if="inPageBlok?.length"
      :isDark="themeState.isDark"
      :blok="inPageBlok[0]"
      @sectionsListActive="onSectionsListActive" />
  </div>

  <!-- Quick links -->
  <BMHeaderRichQuickLinks
    v-if="isQuickLinksEnabled"
    :isOpen="showQuicklinks"
    :isDark="true"
    :logo="logoWhite"
    :quickLinkItems="blok.quick_link_items"
    :productDataActiveId="dataProduct?.id"
    :cards="blok.quick_link_cards"
    @close="onQuicklinksClose" />

  <!-- Menu -->
  <BMHeaderRichMenu
    v-if="isMenuEnabled"
    :isOpen="isMenuActive"
    :isDark="true"
    :logo="logoWhite"
    :linksPrimary="blok.menu_primary_links"
    :linksSecondary="blok.menu_secondary_links"
    :linksTertiary="blok.menu_tertiary_links"
    :alternates="alternates"
    :isSearchEnabled="blok.top_search_state === 'search'"
    @close="onMenuClose" />
</template>

<script lang="ts" setup>
import { gsap } from 'gsap';
import type { ISbAlternateObject, ISbStoryData } from 'storyblok-js-client';
import { computed, nextTick, onMounted, onUnmounted, ref, watch } from 'vue';
import { RouterLink, useRouter } from 'vue-router';
import { BaseIcon, BaseImage, BaseInput, BaseLink } from '~/components/base';
import BMHeaderRichInPage from '~/components/storyblok/basic/BMHeaderRich/BMHeaderRichInPage.vue';
import BmHeaderRichLanguageToggle from '~/components/storyblok/basic/BMHeaderRich/BmHeaderRichLanguageToggle.vue';
import BMHeaderRichMenu from '~/components/storyblok/basic/BMHeaderRich/BMHeaderRichMenu.vue';
import BMHeaderRichQuickLinks from '~/components/storyblok/basic/BMHeaderRich/BMHeaderRichQuickLinks.vue';
import UtilLinkItem from '~/components/storyblok/utils/UtilLinkItem/UtilLinkItem.vue';
import { useBreakpoint } from '~/composables/useBreakpoint';
import { useGsap } from '~/composables/useGsap';
import { useGtmTracking } from '~/composables/useGtmTracking';
import { useHeader } from '~/composables/useHeader';
import { useLocale } from '~/composables/useLocale';
import { useScroll } from '~/composables/useScroll';
import { useShoppingCart } from '~/composables/useShoppingCart';
import { useTheme } from '~/composables/useTheme';
import type { BmHeaderInPageStoryblok, BmHeaderRichStoryblok, DataProductStoryblok } from '~/types/storyblok-generated';
import { useI18n, useRuntimeConfig } from '#imports';
import { initUUID } from '~/utils/uuid';

const props = defineProps<{
  blok: BmHeaderRichStoryblok;
  inPageBlok?: BmHeaderInPageStoryblok[];
  dataProduct?: ISbStoryData<DataProductStoryblok>;
  alternates?: ISbAlternateObject[];
}>();

 
const { easeOut } = useGsap();
const { length: getCartItemNum } = useShoppingCart();
const { locale, hasShop } = useLocale();
const { state: breakpointState } = useBreakpoint();
const { state: headerState } = useHeader();
const { state: scrollState } = useScroll();
const { state: themeState } = useTheme();
const router = useRouter();
const { trackEvent } = useGtmTracking();
const config = useRuntimeConfig();
const { t } = useI18n();

const isMenuEnabled = computed(
  () =>
    props.blok.menu_primary_links?.length &&
    props.blok.menu_secondary_links?.length &&
    props.blok.menu_tertiary_links?.length
);
const isQuickLinksEnabled = computed(
  () => props.blok.quick_link_items?.length && (props.blok.top_product_defaults?.length ?? props.dataProduct?.content)
);
const logo = computed(() => (themeState.isDark ? props.blok.top_center_logo_white : props.blok.top_center_logo_black));
const logoWhite = computed(() => props.blok.top_center_logo_white);

const headerIsVisible = computed(() => {
  return !headerState.collapsed || headerState.preventSlide || showQuicklinks.value || isMenuActive.value;
});

const $header = ref<HTMLElement>();
const $mainNavigation = ref<HTMLElement>();
const $searchBar = ref<HTMLElement>();
const $background = ref<HTMLElement>();
const $defaultMachine = ref<HTMLElement>();
const $defaultMachineImages = ref<HTMLElement[]>();

const isSearchBarActive = ref(false);
const isSectionsListActive = ref(false);
const showQuicklinks = ref(false);
const isMenuActive = ref(false);
const isBackgroundActive = ref(false);
const renderBackgroundInDom = ref(false);
const showBackground = ref(false);
const lastTop = ref(0);
const scrollingDirection = ref('down');
const searchInputValue = ref('');
let searchBarTimeout: ReturnType<typeof setTimeout>;
let removeRouterAfterEach: () => void;

function setHeaderAnimation() {
  if (headerState.preventSlide && !headerState.collapsed) {
    return;
  }

  isBackgroundActive.value = lastTop.value > ($mainNavigation.value?.clientHeight ?? 0);

  if (headerState.preventSlide) {
    isBackgroundActive.value = false;
  }

  if (scrollingDirection.value === 'down') {
    headerState.collapsed = true;
  } else {
    headerState.collapsed = false;
  }
}

function toggleSearchBar(value: boolean) {
  if (value) {
    // Set value first then show to avoid flicker
    isSearchBarActive.value = value;
    void nextTick(() => {
      gsap.to($searchBar.value as HTMLElement, {
        opacity: 1,
        duration: 0.05,
      });
    });
    trackEvent({ event: 'clickOnSearch' });
  } else {
    // Hide first then set value to avoid flicker
    gsap.to($searchBar.value as HTMLElement, {
      opacity: 0,
      duration: 0.1,
    });
    clearTimeout(searchBarTimeout);
    searchBarTimeout = setTimeout(() => {
      isSearchBarActive.value = value;
    }, 100);
  }
}

function animateProducts() {
  const defaultMachine = $defaultMachine.value;

  if (!defaultMachine) {
    return;
  }

  const $images = defaultMachine.querySelectorAll<HTMLElement>('.pdp-machine');

  gsap.to(defaultMachine, {
    x: -32, // 32px is the width of the child element
    duration: 0.8,
     
    ease: easeOut, // Use ease in-out for smooth transition
    delay: 8,
    onComplete: () => {
      // Move the first child element to the end
      const firstChild = $images[0];
      if (!firstChild) {
        return;
      }
      defaultMachine.appendChild(firstChild);

      gsap.set(defaultMachine, { x: 0 });
      // Restart the animation
      animateProducts();
    },
  });
}

function toggleMachinesFlyout() {
  if (!showQuicklinks.value) {
    trackEvent({ event: 'machineFlyout' });
  }
  showQuicklinks.value = true;
}

function onQuicklinksClose() {
  showQuicklinks.value = false;
}

function onMenuClose() {
  isMenuActive.value = false;
}

function toggleMenu() {
  isMenuActive.value = !isMenuActive.value;
}

function onSectionsListActive(value: boolean) {
  isSectionsListActive.value = value;
}

watch(
  () => scrollState.top,
  value => {
    if (scrollState.top < 0) {
      // Prevent negative values on Safari
      return;
    }
    scrollingDirection.value = value > lastTop.value ? 'down' : 'up';
    lastTop.value = value;
    setHeaderAnimation();
  }
);

let domRemovalTimeout: ReturnType<typeof setTimeout>;

watch(
  () => isBackgroundActive.value || isSectionsListActive.value,
  newShowBackground => {
    clearTimeout(domRemovalTimeout);
    if (newShowBackground) {
      renderBackgroundInDom.value = true;
      void nextTick(() => (showBackground.value = true));
    } else {
      showBackground.value = false;
      domRemovalTimeout = setTimeout(() => {
        renderBackgroundInDom.value = false;
      }, 400);
    }
  }
);

watch(
  () => headerState.preventSlide,
  () => {
    if (headerState.preventSlide) {
      headerState.collapsed = false;
      isBackgroundActive.value = false;
    }
  }
);

async function setQuicklinksVisibility() {
  if (props.dataProduct?.content) {
    // Wait for the page to load to set the height of the accordions in the quicklinks
    showQuicklinks.value = true;
    await nextTick();
    showQuicklinks.value = false;
  }
}

/**
 * Set header height on viewport resize
 */
function updateHeaderHeight() {
  scrollState.headerHeight = breakpointState.isTablet ? 72 : 48;
}
watch(() => breakpointState.isTablet, updateHeaderHeight);
updateHeaderHeight();

onMounted(() => {
  // We initialize the UUID here to make sure it's available in the shop as well
  initUUID();

  if (!props.dataProduct?.content && props.blok.top_product_defaults?.length) {
    animateProducts();
  }
  void setQuicklinksVisibility();

  // Listen to navigation attempts to the same path and close any menu
  removeRouterAfterEach = router.afterEach((to, from) => {
    if (to.fullPath === from.fullPath) {
      showQuicklinks.value = false;
      headerState.menuOpen = false;
      isMenuActive.value = false;
      headerState.collapsed = false;
    }
  });
});

onUnmounted(() => {
  clearTimeout(searchBarTimeout);
  removeRouterAfterEach();
});

function handleSearchSubmit() {
  trackEvent({ event: 'search', searchTerm: searchInputValue.value });
}
</script>

<style src="./BMHeaderRich.scss" lang="scss" scoped />
