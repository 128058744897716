import { v4 as uuidv4 } from 'uuid';

/**
 * Generates a UUID v4 and stores it in the local storage if it doesn't exist already.
 */
export function initUUID() {
  if (!localStorage.getItem('wmf-uuid')) {
    localStorage.setItem('wmf-uuid', uuidv4());
  }
  window.dataLayer = window.dataLayer ?? [];
  window.dataLayer.push({ event: 'uuidGenerated', uuid: localStorage.getItem('wmf-uuid') });
}

export function getUUID() {
  if (localStorage.getItem('wmf-uuid')) {
    initUUID();
  }
  return localStorage.getItem('wmf-uuid') as string;
}
