<template>
  <!-- Card -->
  <div
    ref="$card"
    v-editable="blok"
    class="card"
    :class="{
      'has-pointer': blok.interactivity !== 'none',
      'is-square': blok.component === 'item_card-content-svg' || blok.format === 'square',
      'is-portrait': blok.component !== 'item_card-content-svg' && blok.format === 'portrait',
      'is-landscape': blok.component !== 'item_card-content-svg' && blok.format === 'landscape',
    }"
    @click="onCardClick">
    <component
      :is="blok.interactivity === 'link' ? BaseLink : 'div'"
      class="cards-container"
      :link="blok.link"
      @mousemove="tiltCard"
      @mouseleave="resetTilt">
      <!-- Back -->
      <div
        v-if="isFlippable"
        ref="$cardBack"
        class="card-side is-back has-centered-content"
        :class="blok.is_dark ? 'is-dark' : 'is-light'">
        <!-- Flip button -->
        <button
          v-if="isFlippable"
          class="icon-button"
          :class="blok.is_dark ? 'is-dark' : 'is-light'"
          :aria-label="t('global.flipCard')"
          @click="onCardClick">
          <BaseIcon name="fi_repeat" :size="16" color="inherit" class="transition" />
        </button>

        <div ref="$text" class="content is-back">
          <div
            v-if="blok.back_side_text"
            ref="$backSideText"
            class="text"
            :class="[
              isBackTextOverflown
                ? 'text-small-regular'
                : blok.back_side_text?.length >= (type === 'svg' ? 150 : 200)
                  ? 'has-medium-text'
                  : 'has-large-text',
            ]">
            {{ blok.back_side_text }}
          </div>

          <UtilButton v-if="blok.back_side_cta?.length" :blok="blok.back_side_cta[0]">
            {{ blok.back_side_cta[0].text }}
          </UtilButton>
        </div>
      </div>

      <!-- Front -->
      <div
        ref="$cardFront"
        class="card-side"
        :class="[blok.is_dark ? 'is-dark' : 'is-light', { 'has-centered-content': type === 'svg' }]">
        <!-- Buttons -->
        <div
          v-if="blok.interactivity === 'link'"
          class="icon-button"
          :tabindex="-1"
          :class="[blok.is_dark ? 'is-dark' : 'is-light', textLayoutTop ? 'is-bottom' : 'is-top']">
          <BaseIcon name="fi_arrow-right" :size="16" color="inherit" />
        </div>
        <button
          v-if="isFlippable"
          class="icon-button"
          :class="[blok.is_dark ? 'is-dark' : 'is-light', textLayoutTop ? 'is-bottom' : 'is-top']"
          :aria-label="t('global.flipCard')"
          @click="onCardClick">
          <BaseIcon name="fi_repeat" :size="16" color="inherit" />
        </button>

        <!-- Background Media  -->
        <div v-if="blok.component !== 'item_card-content-svg'" class="card-media">
          <div v-if="blok.front_side_video && isVideo(blok.front_side_video?.filename)" class="video-wrapper">
            <BaseVideo
              class="video"
              :src="blok.front_side_video.filename"
              :poster="blok.front_side_image?.filename"
              :autoplay="true"
              :loop="true"
              :posterBreakPointsWidthMap="breakpointsWidthMap"
              loadWhenInView />
          </div>
          <BaseImage
            v-else
            class="bg-image"
            :image="blok.front_side_image"
            :breakpointsWidthMap="breakpointsWidthMap" />
        </div>

        <!-- Headlines -->
        <div
          ref="$headline"
          class="content"
          :style="{
            height: type === 'svg' ? undefined : '100%',
            justifyContent: type === 'number' || textLayoutTop ? 'flex-end' : undefined,
            alignItems: type === 'number' ? 'flex-start' : 'center',
            textAlign: type === 'number' ? undefined : 'center',
          }">
          <!-- SVG Image  -->
          <div v-if="type === 'svg'" class="card-svg">
            <BaseImage :image="blok.front_side_image" :breakpointsWidthMap="breakpointsWidthMap" class="svg-image" />
          </div>

          <!-- Overline -->
          <div
            v-if="
              blok.front_side_overline &&
              (blok.component === 'item_card-content-numbers' ||
                blok.text_layout === 'overline_headline' ||
                blok.text_layout === undefined)
            "
            class="card-small-text">
            {{ blok.front_side_overline }}
          </div>

          <!-- Headline -->
          <div
            v-if="blok.component !== 'item_card-content-numbers' && blok.front_side_headline"
            class="card-headline"
            :class="{
              'card-headline-media': type === 'media',
              'card-headline-svg': type === 'svg',
            }"
            v-html="blok.front_side_headline"></div>

          <!-- Number -->
          <div v-if="blok.component === 'item_card-content-numbers'" class="number">
            {{ numberFormatted }}
          </div>

          <!-- Subline -->
          <div
            v-if="
              blok.front_side_subline &&
              (blok.component === 'item_card-content-numbers' ||
                blok.text_layout === 'headline_subline' ||
                blok.text_layout === undefined)
            "
            :class="[type === 'svg' ? 'card-small-text-svg' : 'card-small-text']">
            {{ blok.front_side_subline }}
          </div>
        </div>
      </div>
    </component>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from '#imports';
import BaseLink from '@/components/base/BaseLink.vue';
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import BaseIcon from '~/components/base/BaseIcon.vue';
import BaseImage from '~/components/base/BaseImage.vue';
import BaseVideo from '~/components/base/BaseVideo.vue';
import UtilButton from '~/components/storyblok/utils/UtilButton/UtilButton.vue';
import { use3dCard } from '~/composables/use3dCard';
import { useScroll } from '~/composables/useScroll';
import { isVideo } from '~/utils/media';
import type {
  ItemCardContentMediaStoryblok,
  ItemCardContentNumbersStoryblok,
  ItemCardContentSvgStoryblok,
} from '~/types/storyblok-generated';

const props = withDefaults(
  defineProps<{
    blok: ItemCardContentMediaStoryblok | ItemCardContentSvgStoryblok | ItemCardContentNumbersStoryblok;
    customTransformPerspective?: number;
    breakpointsWidthMap?: Record<string, number>;
  }>(),
  { breakpointsWidthMap: () => ({ '0': 700 }) }
);

// Create and object with all currencies and their symbols
const CURRENCIES = {
  euro: '€',
  dollar: '$',
  frank: '₣',
  pound: '£',
  yen: '¥',
  won: '₩',
  rial: '﷼',
  percentage: '%',
};

const type = computed((): 'svg' | 'number' | 'media' => {
  switch (props.blok.component) {
    case 'item_card-content-svg':
      return 'svg';
    case 'item_card-content-numbers':
      return 'number';
    default:
      return 'media';
  }
});

// The || operator is a fallback for the content adjustments that probably can be removed if you see this
const isFlippable = computed(() =>
  props.blok.interactivity || false ? props.blok.interactivity === 'flip' : !!props.blok.back_side_text
);
const textLayoutTop = computed(
  () => props.blok.component === 'item_card-content-media' && props.blok.layout === 'text-bottom'
);
const { state: scrollState } = useScroll();
const { t } = useI18n();

const $card = ref<HTMLElement>();
const $cardFront = ref<HTMLElement>();
const $cardBack = ref<HTMLElement>();
const $headline = ref<HTMLElement>();
const $text = ref<HTMLElement>();
const $backSideText = ref<HTMLElement>();

const isFlipped = ref<boolean>(false);
const isBackTextOverflown = ref<boolean>(false);
const tl = ref<GSAPTimeline>();
let timeout: ReturnType<typeof setTimeout>;

const { flipCard, resetTilt, tiltCard, setCard } = use3dCard({
  $card,
  $cardFront,
  $cardBack,
  $headline,
  $text,
  isFlipped,
  isFlippable: isFlippable.value,
  tl,
  options: { transformPerspective: props.customTransformPerspective ?? 1000 },
});

function onCardClick(e: MouseEvent) {
  if (!isFlippable.value) {
    return;
  }
  e.stopPropagation();
  flipCard();
}

// Function to check if back text is overflown
function setIsBackTextOverflown() {
  const text = $backSideText.value as HTMLElement;

  isBackTextOverflown.value = text?.scrollHeight > text?.clientHeight;
}

// Format number
const numberFormatted = computed(() => {
  if (props.blok.component !== 'item_card-content-numbers') {
    return '';
  }

  const number = parseInt(props.blok.front_side_number);
  let numberString = number.toString();

  if (number >= 1000000000) {
    const billions = Math.floor(number / 1000000000);
    const remainder = Math.round((number % 1000000000) / 100000000);
    numberString = `${billions}${remainder !== 0 ? ',' + remainder : ''}B`;
  } else if (number >= 1000000) {
    const millions = Math.floor(number / 1000000);
    const remainder = Math.round((number % 1000000) / 100000);
    numberString = `${millions}${remainder !== 0 ? ',' + remainder : ''}M`;
  } else if (number >= 1000) {
    const thousands = Math.floor(number / 1000);
    const remainder = Math.round((number % 1000) / 100);
    numberString = `${thousands}${remainder !== 0 ? ',' + remainder : ''}K`;
  }

  if (props.blok.front_side_number_prefix) {
    switch (props.blok.front_side_number_prefix) {
      case 'less-than':
        numberString = `< ${numberString}`;
        break;
      case 'greater-than':
        numberString = `> ${numberString}`;
        break;
      default:
        break;
    }
  }

  if (props.blok.front_side_number_unit) {
    numberString += ` ${CURRENCIES[props.blok.front_side_number_unit]}`;
  }

  return numberString;
});

// Watch scroll to reset flip
watch(
  () => scrollState.top,
  () => {
    if (isFlipped.value) {
      flipCard();
    }
  }
);

onBeforeUnmount(() => {
  clearTimeout(timeout);
});

onMounted(() => {
  setCard();
  setTimeout(setIsBackTextOverflown, 100);
});
</script>

<style src="./ItemCardContent.scss" lang="scss" scoped />
