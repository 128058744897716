<template>
  <Teleport to="#teleports">
    <BaseModal :isOpen="isOpen" :isDark="blok.is_dark" :hasBackground="false">
      <div ref="$successModal" class="base-form-success-modal">
        <div ref="$successBanner" class="banner">
          <div class="gradient-line is-top"></div>
          <button class="close" :aria-label="t('global.closeModal')" @click="closeModal">
            <BaseIcon name="fi_x" :size="24" color="white" />
          </button>

          <div class="media">
            <!-- image -->
            <UtilMediaResponsive
              v-if="blok.success_media"
              :image="blok.success_media"
              :video="blok.success_media"
              :autoplay="true"
              :breakpointsWidthMap="{ 0: 415, 520: 641, 768: 538 }"
              aspectRatio="16/9" />
          </div>

          <div class="content">
            <h3 class="headline">
              {{ blok.success_headline }}
            </h3>

            <div class="text">
              <BaseRichtext :text="blok.success_text" :isDark="true" textSize="medium" />
            </div>

            <!-- button -->
            <UtilButton
              :blok="{
                component: 'util_button',
                text: blok.success_button,
                size: 'large',
                theme: 'primary',
                _uid: 'success-button',
              }"
              :isBackgroundDark="blok.is_dark"
              :onClick="closeModal" />
          </div>
          <div class="gradient-line is-bottom"></div>
        </div>
      </div>
    </BaseModal>
  </Teleport>
</template>

<script lang="ts" setup>
import { useI18n } from '#imports';
import { gsap } from 'gsap';
import { onBeforeUnmount, ref } from 'vue';
import { BaseIcon, BaseModal, BaseRichtext } from '~/components/base';
import UtilButton from '~/components/storyblok/utils/UtilButton/UtilButton.vue';
import UtilMediaResponsive from '~/components/storyblok/utils/UtilMediaResponsive/UtilMediaResponsive.vue';
import { useGsap } from '~/composables/useGsap';
import type { FmContactStoryblok, FmNewsletterStoryblok } from '~/types/storyblok-generated';

defineProps<{ blok: FmContactStoryblok | FmNewsletterStoryblok }>();
defineExpose({ openModal });

const { easeOut } = useGsap();

const $successModal = ref<HTMLElement>();
const $successBanner = ref<HTMLElement>();
const isOpen = ref<boolean>();
const { t } = useI18n();
let transitionTimeout: ReturnType<typeof setTimeout>;

function openModal() {
  isOpen.value = true;
  clearTimeout(transitionTimeout);
  transitionTimeout = setTimeout(() => {
    if ($successModal.value) {
      gsap.to($successModal.value, {
        duration: 0.5,
        opacity: 1,
        ease: easeOut,
      });
    }

    if ($successBanner.value) {
      gsap.to($successBanner.value, {
        duration: 0.5,
        y: 0,
        ease: easeOut,
      });
    }
  }, 100);
}

function closeModal() {
  if ($successModal.value) {
    gsap.to($successModal.value, {
      duration: 0.5,
      opacity: 0,
      ease: easeOut,
    });
  }
  if ($successBanner.value) {
    gsap.to($successBanner.value, {
      duration: 0.5,
      y: 40,
      ease: easeOut,
      onComplete: () => {
        isOpen.value = false;
      },
    });
  }
}

onBeforeUnmount(() => {
  clearTimeout(transitionTimeout);
});
</script>

<style src="./BaseFormSuccessModal.scss" lang="scss" scoped />
