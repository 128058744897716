import { default as _91_46_46_46slug_93HK7CmahDuKMeta } from "/codebuild/output/src2947640763/src/seb-brand-platform/apps/wmf/pages/[...slug].vue?macro=true";
import { default as component_45stubssEc8ZXXseMeta } from "/codebuild/output/src2947640763/src/seb-brand-platform/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubssEc8ZXXse } from "/codebuild/output/src2947640763/src/seb-brand-platform/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___en_com",
    path: "/en_com/:slug(.*)*",
    component: () => import("/codebuild/output/src2947640763/src/seb-brand-platform/apps/wmf/pages/[...slug].vue")
  },
  {
    name: "slug___de_de",
    path: "/de_de/:slug(.*)*",
    component: () => import("/codebuild/output/src2947640763/src/seb-brand-platform/apps/wmf/pages/[...slug].vue")
  },
  {
    name: component_45stubssEc8ZXXseMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubssEc8ZXXse
  },
  {
    name: component_45stubssEc8ZXXseMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubssEc8ZXXse
  },
  {
    name: component_45stubssEc8ZXXseMeta?.name,
    path: "/en-sitemap.xml",
    component: component_45stubssEc8ZXXse
  },
  {
    name: component_45stubssEc8ZXXseMeta?.name,
    path: "/de-DE-sitemap.xml",
    component: component_45stubssEc8ZXXse
  }
]